/***********************************************/
// Capa de Servicios para el modulo de Clinicas.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async reload() {
    this.header = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
    };
  }

  async getRecordSlu(slu) {
    try {
      let response = await axios.get(`${this.#baseUrl}/slu/clinics/${slu}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/clinics");
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList(active=false, city='', municipality='') {
    try {
      let response = await axios.get(`${this.#baseUrl}/view-list/clinics`, {
        params: {
          active: active,
          city: city,
          municipality: municipality,
        }
      });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/clinics/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    await this.reload();
    try {
      return axios.post(this.#baseUrl + "/clinics", {
          name: formData.name,
          description: formData.description,
          description_english: formData.description_english,
          email: formData.email,
          address: formData.address,
          phone1: formData.phone1,
          phone2: formData.phone2,
          web_page: formData.web_page,
          sanitary_license: formData.sanitary_license ? formData.sanitary_license : "",
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          facebook_profile: formData.facebook_profile,
          instagram_profile: formData.instagram_profile,
          membership: formData.membership,
          servicehours: formData.servicehours,
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          active: formData.active,
          typeEntity: formData.typeEntity,
          coparmex: formData.coparmex,
          excellence: formData.excellence,
          state: formData.state,
          comment: formData.comment,
          comment_ceetsb: formData.comment_ceetsb,
          comment_coparmex: formData.comment_coparmex,
          comment_coepris: formData.comment_coepris,
          state_ceetsb: formData.state_ceetsb,
          state_coparmex: formData.state_coparmex,
          state_coepris: formData.state_coepris,
          videos: formData.videos,
          repsabi: formData.repsabi,
          province: formData.province,
          municipality: formData.municipality,
          trial: formData.trial,
        }, {
          headers: this.header
        });
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    try {
      await this.reload();
      return axios.put(
        `${this.#baseUrl}/clinics/${formData.id}`,
        {
          id: formData.id,
          name: formData.name,
          description: formData.description,
          description_english: formData.description_english,
          email: formData.email,
          address: formData.address,
          phone1: formData.phone1,
          phone2: formData.phone2,
          web_page: formData.web_page,
          sanitary_license: formData.sanitary_license ? formData.sanitary_license : "",
          map_lat: formData.map_lat,
          map_lng: formData.map_lng,
          facebook_profile: formData.facebook_profile,
          instagram_profile: formData.instagram_profile,
          membership: formData.membership,
          servicehours: formData.servicehours,
          vencimiento_fecha: formData.vencimiento_fecha,
          coepris_qr_link: formData.coepris_qr_link,
          active: formData.active,
          typeEntity: formData.typeEntity,
          coparmex: formData.coparmex,
          state: formData.state,
          comment: formData.comment,
          comment_ceetsb: formData.comment_ceetsb,
          comment_coparmex: formData.comment_coparmex,
          comment_coepris: formData.comment_coepris,
          state_ceetsb: formData.state_ceetsb,
          state_coparmex: formData.state_coparmex,
          state_coepris: formData.state_coepris,
          videos: formData.videos,
          repsabi: formData.repsabi,
          excellence: formData.excellence,
          province: formData.province,
          municipality: formData.municipality,
          trial: formData.trial,
        },
        {
          headers: this.header
        }
      );
    } catch (error) {
      return this.handleError(error);
    }
  }

  async updateCertification(data){
    return axios.put(`${this.#baseUrl}/update-certification/clinics`, data,
                {
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Requested-With': 'XMLHttpRequest',
                      'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                      'Token-Google-Authenticator': sessionStorage.getItem('2fa')
                    }
                }
            )
  }

  async deleteRecord(id) {
    try {
      await this.reload();
      let response = await axios.delete(`${this.#baseUrl}/clinics/${id}`, {
          headers: this.header
        });
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async registerSpecialties(clinic_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("clinic_id", clinic_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/register-specialties/clinics",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteSpecialties(clinic_id, specialtiesIds) {
    try {
      await this.reload();
      let formData = new FormData();
      formData.append("clinic_id", clinic_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/delete-specialties/clinics",
        formData,
        {
          headers: this.header
        }
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(clinic_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("clinic_id", clinic_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/clinics",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(clinic_id, picture_id) {
    if (picture_id) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("clinic_id", clinic_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/clinics",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  //  qr

  async deleteQr(clinic_id, coepris_qr) {
    if (coepris_qr) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("clinic_id", clinic_id);
        formData.append("coepris_qr", coepris_qr);
        let response = await axios.post(
          this.#baseUrl + "/delete-qr/clinics",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async uploadQr(clinic_id, qr_code_id, imageFile, isQr = true) {
    if (imageFile) {
      try {
        await this.reload();
        let formData = new FormData();
        formData.append("clinic_id", clinic_id);
        formData.append("qr_code_id", qr_code_id);
        formData.append("file", imageFile);
        formData.append("isQr", isQr);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/clinics",
          formData,
          {
            headers: this.header
          }
        );
        if (response.data.success) {
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  ///////////////////////////////////////////////////

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/clinics?id=${profile_id}&email=${email}`
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
    }
    return valueError;
  }
})();
